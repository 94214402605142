<template>
   <div class="list-desktop">
        <ul>
            <a v-for="( item, index ) in list" @click="itemActive(index)" :key="index" 
            :title="item.title" :index="index" class="accordion-item-desktop"
            :class="item.opened ? 'accordion-item-opened' : ''"
            >
            {{ item.title }}
            </a>
        </ul>
        <div class="item-content">
            <div>
                <h4>{{ itemActiveObject.title }}</h4>
                <p v-html="itemActiveObject.summary" />
            </div>
            <div class="gt-sm">
                <img v-if="itemActiveObject.img" :src="itemActiveObject.img"/>
            </div>
        </div>
   </div>
</template>
<script setup>
import { accordion } from '../scripts/accordion';
import { onMounted, defineProps, ref } from 'vue'

const props = defineProps({
    list: {
        type: Array,
        required: true
    }
})

const itemActiveObject = ref({ ...props.list[0] })

const itemActive = (index) => {
    itemActiveObject.value = { ...props.list[index] }
}

onMounted(() => {
  accordion()
})
</script>
<style scoped>
    @import url('../styles/divulgation.css');

    a {
        width: 30vw;
        max-width: 330px;
    }

    .list-desktop {
        display: flex;
        align-items: start;
        padding-top: 30px;
    }

    .item-content {
        margin-left: 76px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .item-content div:last-child {
        margin-left: 28px;
    }

    .item-content div:first-child {
        padding: 2rem;
        border: 1px solid #F2F3F4;
        border-radius: 8px;
    }

    @media ( min-width: 1024px ) {
        ul a {
            width: 330px;
        }
    }
</style>